import {
    DiscountSchemeRestDto,
    ProductFilterConditionRestDto,
    ProductFilterCriteriumBrandRestDto,
    ProductFilterCriteriumCategoryRestDto,
    ProductFilterCriteriumTrademarkRestDto,
    ProductFilterRestDto, ShoppingCartDiscountSchemeRestDto
} from "@/api/pharma-cpc-mgmt/models";
import {DpException} from "@/exception/DpException";
import {formatDateObjectToDateString} from "@/helpers/functions/date-time";
import {emptyMultilingualTextField} from "@/helpers/functions/multilingual";
import {ProductFilterRestDtoModel} from "@/models/api/pharma-cpc-mgmt/ProductFilterRestDtoModel";
import {DiscountSchemeTypeEnum} from "@/api/enums/discount-scheme-type-enum";
import {DiscountTypeEnum} from "@/api/enums/discount-type-enum";
import {StatusEnum} from "@/api/enums/status-enum";
import {ProductFilterConditionRestDtoModel} from "@/models/api/pharma-cpc-mgmt/ProductFilterConditionRestDtoModel";
import {MultilingualTextFieldRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {ShoppingCartDiscountTypeEnum} from "@/api/enums/shopping-cart-discount-type-enum";

function addProductFilter(product_filter: ProductFilterRestDtoModel, filter_condition_type?: string): ProductFilterRestDtoModel {
    if (!product_filter.filter_conditions) {
        product_filter.filter_conditions = [];
    }
    if (product_filter.filter_conditions.length < 3) {
        product_filter.filter_conditions.push(emptyFilterCondition(filter_condition_type));
    } else {
        //TODO ask validation
        throw [new DpException('Maximum amount of filter conditions reached: ' + product_filter.filter_conditions.length)];
    }

    return product_filter;
}

function emptyDiscountScheme(): DiscountSchemeRestDto {
    return {
        name: '',
        scheme_type_code: DiscountSchemeTypeEnum.MANUAL_INPUT,
        type_code: DiscountTypeEnum.PERCENTAGE,
        discount_value: null,
        client_group_code: null,
        max_allowed_discount_amount: null,
        discount_message: emptyMultilingualTextField(),
        exclude_prescription_required: false,
        is_standard_price_visible: true,
        is_discount_visible: true,
        product_filter: ProductFilterRestDtoModel.createWithDefaults(),
        begin_date: formatDateObjectToDateString(new Date()),
        end_date: null,
        status_code: StatusEnum.NOT_PUBLISHED,
        unapplied_message: createDefaultUnappliedMessage()
    };
}

function emptyShoppingCartDiscountScheme(): ShoppingCartDiscountSchemeRestDto {
    return {
        name: '',
        title: emptyMultilingualTextField(),
        promo_code: '',
        promo_message: emptyMultilingualTextField(),
        minimum_purchase_amount: null,
        is_promo_cumulative_with_discounts: false,
        promo_max_usage: null,
        promo_max_usage_per_user: null,
        type_code: ShoppingCartDiscountTypeEnum.PERCENTAGE_FOR_SHOPPING_CART_PART,
        discount_value: null,
        client_group_code: null,
        exclude_prescription_required: false,
        product_filter: ProductFilterRestDtoModel.createWithDefaults(),
        begin_date: formatDateObjectToDateString(new Date()),
        end_date: null,
        status_code: StatusEnum.NOT_PUBLISHED
    };
}

function createDefaultUnappliedMessage() {
    return {
        nl: 'Voeg producten toe om van de aanbieding te genieten.',
        fr: 'Ajouter des produits pour profiter de l\'offre',
        en: 'Add products to benefit from the offer',
        de: 'Produkte hinzufügen, um von dem Angebot zu profitieren',
    } as MultilingualTextFieldRestDto;
}

function emptyFilterCondition(filter_condition_type?: string): ProductFilterConditionRestDtoModel {
    switch (filter_condition_type) {
        case 'all':
            return {
                include_all_products: true,
                cnk_codes_excluded: [],
            };
        case 'cnk-list':
            return {
                cnk_codes_included: [],
            };
        case 'brands-categories':
            return {
                cnk_codes_excluded: [],
                product_brand_filters: [],
                product_category_filters: [],
            };
        case 'trademarks-categories':
            return {
                cnk_codes_excluded: [],
                product_trademark_filters: [],
                product_category_filters: [],
            };
        default:
            return {
                include_all_products: false,
                cnk_codes_included: [],
                cnk_codes_excluded: [],
                product_brand_filters: [],
                product_trademark_filters: [],
                product_category_filters: [],
            };
    }
}

function emptyProductFilter(): ProductFilterRestDtoModel {
    return ProductFilterRestDtoModel.createWithDefaults();
}

function removeProductFilter(product_filter: ProductFilterRestDto, filter_condition_index: number): ProductFilterRestDto {
    product_filter.filter_conditions = product_filter.filter_conditions
        .filter((filter_condition: ProductFilterConditionRestDto, index: number) => {
            return index !== filter_condition_index;
        });

    return product_filter;
}

function removeProductBrandFilter(product_filter: ProductFilterRestDto, filter_index: number, product_brand_filter: ProductFilterCriteriumBrandRestDto): ProductFilterRestDto {
    product_filter.filter_conditions[filter_index].product_brand_filters = product_filter.filter_conditions[filter_index].product_brand_filters?.filter(
        (brand_filter: ProductFilterCriteriumBrandRestDto) => brand_filter.brand_code !== product_brand_filter.brand_code && brand_filter.selection_type_code === product_brand_filter.selection_type_code
    );

    return product_filter;
}

function removeProductTrademarkFilter(product_filter: ProductFilterRestDto, filter_index: number, product_trademark_filter: ProductFilterCriteriumTrademarkRestDto): ProductFilterRestDto {
    product_filter.filter_conditions[filter_index].product_trademark_filters = product_filter.filter_conditions[filter_index].product_trademark_filters?.filter(
        (trademark_filter: ProductFilterCriteriumTrademarkRestDto) => trademark_filter.trademark_code !== product_trademark_filter.trademark_code && trademark_filter.selection_type_code === product_trademark_filter.selection_type_code
    );

    return product_filter;
}

function removeProductCategoryFilter(product_filter: ProductFilterRestDto, filter_index: number, product_category_filter: ProductFilterCriteriumCategoryRestDto): ProductFilterRestDto {
    product_filter.filter_conditions[filter_index].product_category_filters = product_filter.filter_conditions[filter_index].product_category_filters?.filter(
        (category_filter: ProductFilterCriteriumCategoryRestDto) => category_filter.category_code !== product_category_filter.category_code && category_filter.selection_type_code === product_category_filter.selection_type_code
    );

    return product_filter;
}

export {
    addProductFilter,
    emptyDiscountScheme,
    emptyShoppingCartDiscountScheme,
    emptyFilterCondition,
    emptyProductFilter,
    removeProductFilter,
    removeProductBrandFilter,
    removeProductTrademarkFilter,
    removeProductCategoryFilter
};
