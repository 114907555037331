
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import {ShoppingCartDiscountSchemeOverviewRestDto} from "@/api/pharma-cpc-mgmt/models";
import ShoppingCartDiscountsOverviewItem from "@/components/layouts/catalog/discounts-pricelists/ShoppingCartDiscountsOverviewItem.vue";

export default defineComponent({
  name: "ShoppingCartDiscountsOverview",
  components: {ShoppingCartDiscountsOverviewItem},
  emits: ["deleteShoppingCartDiscountScheme"],
  props: {
    isReady: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapGetters('cpc_mgmt', ['getShoppingCartDiscountSchemes']),
    shoppingCartDiscountSchemes(): ShoppingCartDiscountSchemeOverviewRestDto[] {
      return this.getShoppingCartDiscountSchemes;
    },
    hasShoppingCartDiscountSchemes(): Boolean {
      return (Array.isArray(this.shoppingCartDiscountSchemes) && this.shoppingCartDiscountSchemes.length > 0);
    },
  },
  methods: {
    deleteShoppingCartDiscountScheme(id: string) {
          this.$emit('deleteShoppingCartDiscountScheme', id);
    },
  }
});
