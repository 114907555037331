import {config, fetchConfig} from '@/helpers/fetch-config';
import configFile from '../public/config.json';

// Load config in to local storage
fetchConfig().then((loadedConfig) => {
    if (!config || JSON.stringify(config) !== JSON.stringify(loadedConfig)) {
        Storage.local.set(LocalStorageKeyEnum.CONFIG, JSON.stringify(loadedConfig));
        location.reload();
    }
});

import {createApp} from 'vue';

import {abilitiesPlugin} from '@casl/vue';
import ability from '@/config/ability.js';

import 'bootstrap/dist/js/bootstrap.esm';
import '@/assets/scss/main.scss';

import {createPinia} from 'pinia';
/* deprecated */
import store from '@/store/index.js';
import router from '@/router/router.js';
import axios from 'axios';
import * as ConfirmDialog from 'vuejs-confirm-dialog';
import Toaster, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {onError} from '@/helpers/toast-service';

import {configure, Field, Form, ErrorMessage} from 'vee-validate';
import {localize, setLocale} from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';

import App from '@/App.vue';

import BaseAlert from "@/components/UI/Bootstrap/Alert/BaseAlert.vue";
import AlertError from "@/components/UI/Bootstrap/Alert/AlertError.vue";
import AlertInfo from "@/components/UI/Bootstrap/Alert/AlertInfo.vue";
import AlertSuccess from "@/components/UI/Bootstrap/Alert/AlertSuccess.vue";
import AlertWarning from "@/components/UI/Bootstrap/Alert/AlertWarning.vue";

import BaseAccordion from '@/components/UI/Bootstrap/Accordion/BaseAccordion.vue';
import BaseAccordionCollapse from '@/components/UI/Bootstrap/Accordion/BaseAccordionCollapse.vue';
import BaseAccordionHeader from '@/components/UI/Bootstrap/Accordion/BaseAccordionHeader.vue';
import BaseAccordionItem from '@/components/UI/Bootstrap/Accordion/BaseAccordionItem.vue';

import BaseCard from '@/components/UI/Bootstrap/Card/BaseCard.vue';
import BaseCardBody from '@/components/UI/Bootstrap/Card/BaseCardBody.vue';
import BaseCardFooter from '@/components/UI/Bootstrap/Card/BaseCardFooter.vue';
import BaseCardHeader from '@/components/UI/Bootstrap/Card/BaseCardHeader.vue';
import BaseCardImage from '@/components/UI/Bootstrap/Card/BaseCardImage.vue';

import BaseButton from '@/components/UI/BaseButton.vue';
import BaseContainer from '@/components/UI/Bootstrap/BaseContainer.vue';
import BaseDialog from '@/components/UI/BaseDialog.vue';
import BaseModal from '@/components/UI/Bootstrap/Modal/BaseModal.vue';
import BaseModalButton from '@/components/UI/Bootstrap/Modal/BaseModalButton.vue';
import BasePagination from '@/components/UI/Bootstrap/BasePagination.vue';
import BasePaginationItem from '@/components/UI/Bootstrap/BasePaginationItem.vue';
import BasePlaceholderTable from '@/components/UI/Bootstrap/BasePlaceholderTable.vue';
import BaseSpinner from '@/components/UI/Bootstrap/BaseSpinner.vue';
import BaseTab from "@/components/UI/Bootstrap/Tab/BaseTab.vue";
import BaseTabLink from "@/components/UI/Bootstrap/Tab/BaseTabLink.vue";
import BaseTabPane from "@/components/UI/Bootstrap/Tab/BaseTabPane.vue";

import BaseLinkPicker from "@/components/UI/BaseLinkPicker.vue";
import BaseLinkPickerInternalLink from "@/components/UI/BaseLinkPickerInternalLink.vue";
import BaseMoveButtons from "@/components/UI/BaseMoveButtons.vue";

import InputFile from '@/components/UI/InputFile.vue';
import InputFileProfile from '@/components/UI/InputFileProfile.vue';
import InputText from '@/components/UI/InputText.vue';
import InputSelect from '@/components/UI/InputSelect.vue';
import InputRadio from '@/components/UI/InputRadio.vue';
import InputRadioBoolean from "@/components/UI/InputRadioBoolean.vue";
import InputCheckbox from '@/components/UI/InputCheckbox.vue';
import InputCheckboxBoolean from '@/components/UI/InputCheckboxBoolean.vue';
import InputCnkList from '@/components/UI/InputCnkList.vue';
import InputHtml from '@/components/UI/InputHtml.vue';
import InputEmail from '@/components/UI/InputEmail.vue';
import InputNumber from '@/components/UI/InputNumber.vue';
import InputColor from '@/components/UI/InputColor.vue';
import InputDate from "@/components/UI/InputDate.vue";
import InputTime from "@/components/UI/InputTime.vue";
import InputUrl from "@/components/UI/InputUrl.vue";

import CustomerChanger from '@/components/UI/CustomerChanger.vue';
import LocaleChanger from '@/components/UI/LocaleChanger.vue';

axios.defaults.headers.get['Accept'] = 'application/json';

import {CustomerNamesApi} from '@/api/pharma-customer-name';
import {CustomerDomainSettingsAPIApi} from "@/api/pharma-customer-settings";
import {
    SimpleContentBlockEndpointsApi,
    ContentBlockEndpointsApi,
    ContentBlockLibraryEndpointsApi,
    ContentBlockLinkEndpointsApi,
    ContentBlockEnumEndpointsApi
} from "@/api/pharma-cms-content-block";
import {
    ContentPageEndpointsApi,
    ContentPageAbstractEndpointsApi,
    ContentPageEnumEndpointsApi,
    ContentBlockLibraryEndpointsApi as ContentPagesLibraryEndpointsApi,
    ContentPageRowEndpointsApi,
    ContentBlockInRowEndpointsApi,
} from "@/api/pharma-cms-content-page";
import {DocumentAPIApi} from "@/api/pharma-cms-document";
import {EnumEndpointsApi as CmsEnumEndpointsApi} from "@/api/pharma-cms-enum";
import {
    PharmacyInformationEndpointsApi,
    OpeningInfoClosingPeriodsEndpointsApi,
    OpeningInfoOpeningHoursPeriodsEndpointsApi,
    OpeningInfoStandardOpeningHoursEndpointsApi
} from "@/api/pharma-cms-pharmacy";
import {DocumentAPIApi as CpcDocumentAPIApi} from "@/api/pharma-cpc-document";
import {
    CatalogSettingsAPIApi,
    DiscountSchemeAPIApi,
    PricelistAPIApi,
    ProductFiltersAPIApi,
    ShoppingCartDiscountSchemeAPIApi,
    StarterSettingsAPIApi
} from "@/api/pharma-cpc-mgmt";
import {EnumEndpointsApi as CpcEnumEndpointsApi} from "@/api/pharma-cpc-enum";
import {EnumEndpointsApi as PimEnumEndpointsApi} from "@/api/pharma-pim-enum";
import {
    ProductAPIApi,
    ProductBrandAPIApi,
    ProductCategoryAPIApi,
    ProductTrademarkAPIApi
} from "@/api/pharma-cpc-product";
import {CustomerProductsAPIApi, ProductPropertyAPIApi} from "@/api/pharma-cpc-product-mgmt";
import {ClientGroupManagementEndpointsApi} from "@/api/pharma-idm-user";
import {OrcaReservationCampaignAPIApi} from "@/api/pharma-order-orca-customer-mgmt";
import {OrderSettingsAPIApi} from "@/api/pharma-order-settings";
import {ProductFeatureEndpointsApi} from "@/api/pharma-license-license-info";

import customerInstanceImport from "@/stores/axios/axios-pharma-customer";
import customerNameInstanceImport from "@/store/pharma-customer-name/axios-pharma-customer-name";
import customerSettings from "@/store/pharma-customer-settings/axios-pharma-customer-settings";
import cmsContentBlock from '@/store/pharma-cms-content-block/axios-pharma-cms-content-block';
import cmsContentPage from '@/store/pharma-cms-content-page/axios-pharma-cms-content-page';
import cmsDocument from '@/store/pharma-cms-document/axios-pharma-cms-document';
import cmsEnum from '@/store/pharma-cms-enum/axios-pharma-cms-enum';
import cpcDocument from "@/store/pharma-cpc-document/axios-pharma-cpc-document";
import cpcEnum from "@/store/pharma-cpc-enum/axios-pharma-cpc-enum";
import pimEnum from "@/store/pharma-pim-enum/axios-pharma-pim-enum";
import cpcMgmt from "@/store/pharma-cpc-mgmt/axios-pharma-cpc-mgmt";
import cpcProduct from "@/store/pharma-cpc-product/axios-pharma-cpc-product";
import cpcProductMgmt from "@/store/pharma-cpc-product-mgmt/axios-pharma-cpc-product-mgmt";
import idmUser from "@/store/pharma-idm-user/axios-pharma-idm-user";
import licenseProductFeature from "@/store/pharma-license-license-info/axios-pharma-license-license-info";
import orderOrcaCustomerMgmt
    from "@/store/pharma-order-orca-customer-mgmt/axios-pharma-order-orca-customer-mgmt";
import orderSettings from "@/store/pharma-order-settings/axios-pharma-order-settings";
import {CustomerCRUDApi} from "@/api/pharma-customer";

//todo ask why this is needed
const customerInstance = customerInstanceImport;
const customerNameInstance = customerNameInstanceImport;
const customerSettingsInstance = customerSettings;
const cmsContentBlockInstance = cmsContentBlock;
const cmsContentBlockLibraryInstance = cmsContentBlock;
const cmsContentBlockLinkInstance = cmsContentBlock;
const cmsContentPageInstance = cmsContentPage;
const cmsContentPageAbstractInstance = cmsContentPage;
const cmsContentPageEnumInstance = cmsContentPage;
const cmsContentPageLibraryInstance = cmsContentPage;
const cmsContentPageRowInstance = cmsContentPage;
const cmsContentBlockInRowInstance = cmsContentPage;
const cmsDocumentInstance = cmsDocument;
const cmsEnumInstance = cmsEnum;
const CmsPharmacyInstance = cmsContentBlock;
const cmsSimpleContentBlockInstance = cmsContentBlock;
const cmsSimpleImageContentBlock = cmsContentBlock;
const cpcDocumentInstance = cpcDocument;
const cpcEnumInstance = cpcEnum;
const pimEnumInstance = pimEnum;
const cpcMgmtInstance = cpcMgmt;
const cpcProductInstance = cpcProduct;
const cpcProductMgmtInstance = cpcProductMgmt;
const idmUserInstance = idmUser;
const licenseProductFeatureInstance = licenseProductFeature;
const orderSettingsInstance = orderSettings;

export const simpleContentBlockEndpointsApiService = new SimpleContentBlockEndpointsApi(cmsSimpleContentBlockInstance, undefined, configFile.cmsUrl);
export const ContentBlockEndpointsApiService = new ContentBlockEndpointsApi(cmsContentBlockInstance, undefined, configFile.cmsUrl);
export const ContentBlockLibraryEndpointsApiService = new ContentBlockLibraryEndpointsApi(cmsContentBlockLibraryInstance, undefined, configFile.cmsUrl);
export const ContentBlockLinkEndpointsApiService = new ContentBlockLinkEndpointsApi(cmsContentBlockLinkInstance, undefined, configFile.cmsUrl);
export const ContentBlockEnumEndpointsApiService = new ContentBlockEnumEndpointsApi(cmsContentBlockLinkInstance, undefined, configFile.cmsUrl);
export const ContentPageEndpointsApiService = new ContentPageEndpointsApi(cmsContentPageInstance, undefined, configFile.cmsUrl);
export const ContentPageAbstractEndpointsApiService = new ContentPageAbstractEndpointsApi(cmsContentPageAbstractInstance, undefined, configFile.cmsUrl);
export const ContentPageEnumEndpointsApiService = new ContentPageEnumEndpointsApi(cmsContentPageEnumInstance, undefined, configFile.cmsUrl);
export const ContentPagesLibraryEndpointsApiService = new ContentPagesLibraryEndpointsApi(cmsContentPageLibraryInstance, undefined, configFile.cmsUrl);
export const ContentPageRowEndpointsApiService = new ContentPageRowEndpointsApi(cmsContentPageRowInstance, undefined, configFile.cmsUrl);
export const ContentBlockInRowEndpointsApiService = new ContentBlockInRowEndpointsApi(cmsContentBlockInRowInstance, undefined, configFile.cmsUrl);
export const simpleImageContentBlockEndpointsApiService = new SimpleContentBlockEndpointsApi(cmsSimpleImageContentBlock, undefined, configFile.cmsUrl);
export const customerNameCRUDAPIService = new CustomerNamesApi(customerNameInstance, undefined, configFile.customerUrl);
export const CustomerCrudApiService = new CustomerCRUDApi(customerInstance, undefined, configFile.customerUrl);
export const CustomerDomainSettingsEndpointsApiService = new CustomerDomainSettingsAPIApi(customerSettingsInstance, undefined, configFile.customerUrl);
export const DocumentAPIApiService = new DocumentAPIApi(cmsDocumentInstance, undefined, configFile.cmsUrl);
export const CmsEnumEndpointsApiService = new CmsEnumEndpointsApi(cmsEnumInstance, undefined, configFile.cmsUrl);
export const PharmacyInformationEndpointsApiService = new PharmacyInformationEndpointsApi(CmsPharmacyInstance, undefined, configFile.cmsUrl);
export const ClosingPeriodsEndpointsApiService = new OpeningInfoClosingPeriodsEndpointsApi(CmsPharmacyInstance, undefined, configFile.cmsUrl);
export const OpeningInfoOpeningHoursPeriodsEndpointsApiService = new OpeningInfoOpeningHoursPeriodsEndpointsApi(CmsPharmacyInstance, undefined, configFile.cmsUrl);
export const StandardOpeningHoursEndpointsApiService = new OpeningInfoStandardOpeningHoursEndpointsApi(CmsPharmacyInstance, undefined, configFile.cmsUrl);
export const CpcCatalogSettingsEndpointsApiService = new CatalogSettingsAPIApi(cpcMgmtInstance, undefined, configFile.cpcUrl);
export const CpcDocumentEndpointsApiService = new CpcDocumentAPIApi(cpcDocumentInstance, undefined, configFile.cpcUrl);
export const CpcDiscountSchemeEndpointsApiService = new DiscountSchemeAPIApi(cpcMgmtInstance, undefined, configFile.cpcUrl);
export const CpcShoppingCartDiscountSchemeEndpointsApiService = new ShoppingCartDiscountSchemeAPIApi(cpcMgmtInstance, undefined, configFile.cpcUrl);
export const CpcEnumEndpointsApiService = new CpcEnumEndpointsApi(cpcEnumInstance, undefined, configFile.cpcUrl);
export const PimEnumEndpointsApiService = new PimEnumEndpointsApi(pimEnumInstance, undefined, configFile.pimUrl);
export const CpcPriceListEndpointsApiService = new PricelistAPIApi(cpcMgmtInstance, undefined, configFile.cpcUrl);
export const CpcProductFiltersEndpointsApiService = new ProductFiltersAPIApi(cpcMgmtInstance, undefined, configFile.cpcUrl);
export const CpcStarterSettingsEndpointsApiService = new StarterSettingsAPIApi(cpcMgmtInstance, undefined, configFile.cpcUrl);
export const CpcProductEndpointsApiService = new ProductAPIApi(cpcProductInstance, undefined, configFile.cpcProductUrl);
export const CpcProductBrandEndpointsApiService = new ProductBrandAPIApi(cpcProductInstance, undefined, configFile.cpcProductUrl);
export const CpcProductTrademarkEndpointsApiService = new ProductTrademarkAPIApi(cpcProductInstance, undefined, configFile.cpcProductUrl);
export const CpcProductCategoryEndpointsApiService = new ProductCategoryAPIApi(cpcProductInstance, undefined, configFile.cpcProductUrl);
export const CpcProductMgmtCustomerProductsEndpointsApiService = new CustomerProductsAPIApi(cpcProductMgmtInstance, undefined, configFile.cpcUrl);
export const CpcProductMgmtProductPropertyEndpointsApiService = new ProductPropertyAPIApi(cpcProductMgmtInstance, undefined, configFile.cpcUrl);
export const ClientGroupManagementEndpointsApiService = new ClientGroupManagementEndpointsApi(idmUserInstance, undefined, configFile.idmUrl);
export const OrcaReservationCampaignApiService = new OrcaReservationCampaignAPIApi(orderOrcaCustomerMgmt, undefined, configFile.orderUrl);
export const OrderSettingsEndpointsApiService = new OrderSettingsAPIApi(orderSettingsInstance, undefined, configFile.orderUrl);
export const ProductFeatureEndpointsApiService = new ProductFeatureEndpointsApi(licenseProductFeatureInstance, undefined, configFile.licenseUrl);

const pinia = createPinia();

const app = createApp(App);

/*// Docs - https://vue-i18n.intlify.dev/
const i18n = createI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages: {
        nl: messages_nl,
        en: messages_en,
        fr: messages_fr,
    },
    //TODO remove this when XSS protection is done
    warnHtmlInMessage: false,
});*/

import i18n from "@/modules/i18n";

app.use(router);
app.use(pinia);
const ClipboardStore = useClipboardStore();
const CustomerStore = useCustomerStore();
const PharmacyStore = usePharmacyStore();
/* deprecated */
app.use(store);
app.use(i18n);
app.use(VueSweetalert2);
app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
});
app.use(ConfirmDialog);
app.use(Toaster, {
    container: document.querySelector('body'),
    position: POSITION.TOP_RIGHT,
    timeout: 5000
});

app.component('base-alert', BaseAlert);
app.component('alert-error', AlertError);
app.component('alert-info', AlertInfo);
app.component('alert-success', AlertSuccess);
app.component('alert-warning', AlertWarning);

app.component('base-accordion', BaseAccordion);
app.component('base-accordion-collapse', BaseAccordionCollapse);
app.component('base-accordion-header', BaseAccordionHeader);
app.component('base-accordion-item', BaseAccordionItem);
app.component('base-card', BaseCard);
app.component('base-card-body', BaseCardBody);
app.component('base-card-footer', BaseCardFooter);
app.component('base-card-header', BaseCardHeader);
app.component('base-card-image', BaseCardImage);
app.component('base-button', BaseButton);
app.component('base-container', BaseContainer);
app.component('base-dialog', BaseDialog);
app.component('base-modal', BaseModal);
app.component('base-modal-button', BaseModalButton);
app.component('base-pagination', BasePagination);
app.component('base-pagination-item', BasePaginationItem);
app.component('base-placeholder-table', BasePlaceholderTable);
app.component('base-spinner', BaseSpinner);
app.component('base-tab', BaseTab);
app.component('base-tab-link', BaseTabLink);
app.component('base-tab-pane', BaseTabPane);

app.component('base-link-picker', BaseLinkPicker);
app.component('base-link-picker-internal-link', BaseLinkPickerInternalLink);
app.component('base-move-buttons', BaseMoveButtons);

app.component('input-color', InputColor);
app.component('input-file', InputFile);
app.component('input-file-profile', InputFileProfile);
app.component('input-text', InputText);
app.component('input-select', InputSelect);
app.component('input-radio', InputRadio);
app.component('input-radio-boolean', InputRadioBoolean);
app.component('input-checkbox', InputCheckbox);
app.component('input-checkbox-boolean', InputCheckboxBoolean);
app.component('input-cnk-list', InputCnkList);
app.component('input-html', InputHtml);
app.component('input-email', InputEmail);
app.component('input-number', InputNumber);
app.component('input-date', InputDate);
app.component('input-time', InputTime);
app.component('input-url', InputUrl);

app.component('locale-changer', LocaleChanger);
app.component('customer-changer', CustomerChanger);

// Vee-validate components
app.component('dp-form', Form);
app.component('dp-field', Field);
app.component('dp-error-message', ErrorMessage);

import {DIKeyEnum} from "@/di/DIKeyEnum";
import {Config} from "@/models/facade/Config";

app.provide(DIKeyEnum.CONFIG, Config.getInstance());

import {datadogLogs} from "@datadog/browser-logs";

datadogLogs.init({
    site: 'datadoghq.eu',
    clientToken: configFile.datadog.clientToken,
    service: configFile.datadog.serviceName,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'debug'],
    sampleRate: 100,
})

// Global error handler
app.config.errorHandler = (error) => {
    console.error(error);
    onError(error);
};

const vm = app.mount('#app');

import './helpers/vee-validate/rules';
import {Storage} from "@/models/facade/Storage";
import {LocalStorageKeyEnum} from "@/models/enum/LocalStorageKeyEnum";

import {useClipboardStore} from "@/stores/ClipboardStore";
import {useCustomerStore} from "@/stores/CustomerStore";
import {usePharmacyStore} from "@/stores/PharmacyStore";

configure({
    generateMessage: localize({en, nl, fr}),
});

setLocale('nl');

export {ClipboardStore, CustomerStore, PharmacyStore};
export default vm;
