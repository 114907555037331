
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions} from "vuex";
import DiscountsPriceListsOverview
  from "@/components/layouts/catalog/discounts-pricelists/DiscountsPriceListsOverview.vue";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import ShoppingCartDiscountsOverview from "@/components/layouts/catalog/discounts-pricelists/ShoppingCartDiscountsOverview.vue";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "DiscountsPriceLists",
  components: {
    ShoppingCartDiscountsOverview,
    BaseTitle,
    CatalogBuildStatus,
    DiscountsPriceListsOverview,
  },
  data: () => ({
    exceptions: [] as Exception[],
    isReadyDiscountSchemes: false as Boolean,
    isReadyPriceLists: false as Boolean,
    isReadyShoppingCartDiscounts: false as Boolean,
    savedSuccessfully: false as Boolean,
    accordionId: 'accordionDiscountsPrices' as String,
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    AbilityContext() {
      return AbilityContext
    },
  },
  methods: {
    ...mapActions('cpc_mgmt', {
      deleteDS: 'deleteDiscountScheme',
      deletePL: 'deletePriceList',
      deleteSCD: 'deleteShoppingCartDiscountScheme',
      moveDS: 'moveDiscountScheme',
      movePL: 'movePriceList',
      searchDS: 'searchDiscountSchemes',
      searchPL: 'searchPriceLists',
      searchSCD: 'searchShoppingCartDiscountSchemes',
    }),
    async deleteDiscountScheme(id: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReadyDiscountSchemes = false;

          await this.deleteDS(id);
          await this.searchDiscountSchemes();
        } else {
          return;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async deletePriceList(id: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReadyPriceLists = false;

          await this.deletePL(id);
          await this.searchPriceLists();
        } else {
          return;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async deleteShoppingCartDiscountScheme(id: string) {
      try {
        const answer = window.confirm(this.$t('deleteConfirmation'));

        if (answer) {
          this.isReadyShoppingCartDiscounts = false;

          await this.deleteSCD(id);
          await this.searchShoppingCartDiscountSchemes();
        } else {
          return;
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async moveDiscountScheme(id: string, moveAction: MoveActionEnum) {
      this.isReadyDiscountSchemes = false;

      try {
        await this.moveDS({
          id: id,
          move_action: moveAction,
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      await this.searchDiscountSchemes();
    },
    async movePriceList(id: string, moveAction: MoveActionEnum) {
      this.isReadyPriceLists = false;

      try {
        await this.movePL({
          id: id,
          move_action: moveAction,
        });
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      await this.searchPriceLists();
    },
    async reloadContent() {
      try {
        await Promise.all([
          this.searchDiscountSchemes(),
          this.searchShoppingCartDiscountSchemes(),
          this.searchPriceLists(),
        ])
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }
    },
    async searchDiscountSchemes() {
      this.isReadyDiscountSchemes = false;

      try {
        await this.searchDS();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReadyDiscountSchemes = true;
    },
    async searchPriceLists() {
      this.isReadyPriceLists = false;

      try {
        await this.searchPL();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReadyPriceLists = true;
    },
    async searchShoppingCartDiscountSchemes() {
      this.isReadyShoppingCartDiscounts = false;

      try {
        await this.searchSCD();
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReadyShoppingCartDiscounts = true;
    },
  }
});
