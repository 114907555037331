
import {defineComponent, ref} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import ProductFilters from "@/components/layouts/catalog/product-filter/ProductFilters.vue";
import {ProductFilterRestDto} from "@/api/pharma-cpc-mgmt/models";
import {generateUuid} from "@/helpers/functions/string";
import {generateProductFilterPreviewUrl} from "@/helpers/functions/route";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {ProductFilterApplyTypeEnum} from "@/models/enum/ProductFilterApplyTypeEnum";
import {mapActions as mapActionsP} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import DiscountPricelistSection from "@/components/layouts/catalog/discounts-pricelists/DiscountPriceListSection.vue";
import ProductLabel from "@/components/layouts/catalog/product-labels/ProductLabel.vue";
import {I18nContext} from "@/context/I18nContext";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import BaseOffcanvasButtonOpen from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonOpen.vue";
import BaseOffcanvas from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvas.vue";
import {GlobalFilterExcludeConditionsRestDtoModel} from "@/models/api/pharma-cpc-mgmt/GlobalFilterExcludeConditionsRestDtoModel";

export default defineComponent({
  name: "ProductFiltersDetail",
  components: {
    BaseOffcanvas,
    BaseOffcanvasButtonOpen,
    MultilingualSwitcher,
    ProductLabel,
    DiscountPricelistSection,
    BaseTitle,
    ProductFilters,
    CatalogBuildStatus},
  props: ["mode"],
  data() {
    return {
      code: this.$route.params.code ?? generateUuid() as string,
      exceptions: [] as Exception[],
      isReady: false as boolean,
      returnRouteObject: {name: 'product-filters'} as object,
      savedSuccessfully: false as boolean,
      activeLanguage: I18nContext.getDefaultApiLanguageKey(),
      //Hack: and errors object has to be passed to the MultilingualSwitcher, but I can't use the errors object from the form,
      // because this switcher is not inside the form.
      languageSwitchErrors: ref([]),
      labelKey: 0,
      productFilter: null as ProductFilterRestDto | null,
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ProductFilterApplyTypeEnum() {
      return ProductFilterApplyTypeEnum
    },
    PageDetailModeEnum() {
      return PageDetailModeEnum
    },
    ...mapGetters("cpc_mgmt", ["getComplexProductFilter"]),
    previewUrl(): string | null {
      if (this.code) {
        return generateProductFilterPreviewUrl(this.code as string);
      }

      return null;
    }
  },
  methods: {
    ...mapActions("cpc_mgmt", [
      "clearComplexProductFilter",
      "deleteProductFilter",
      "searchComplexProductFilter",
      "saveComplexProductFilter"
    ]),
    ...mapActionsP(useCustomerStore, ["searchAllBrandsCustomer", "searchAllTrademarksCustomer", "searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer"]),
    async afterSave() {
      if (this.mode === 'new') {
        await this.$router.push(this.returnRouteObject);
      } else {
        window.scrollTo(0, 0);
        await this.reloadContent();

        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.mode === 'new') {
          this.clearComplexProductFilter();
          await Promise.all([
            this.searchAllBrandsCustomer(),
            this.searchAllTrademarksCustomer(),
            this.searchAllCategoriesCustomer(),
            this.searchProductCategoriesTreeCustomer()
          ]);
        } else {
          await Promise.all([
            this.searchComplexProductFilter(this.code),
            this.searchAllBrandsCustomer(),
            this.searchAllTrademarksCustomer(),
            this.searchAllCategoriesCustomer(),
            this.searchProductCategoriesTreeCustomer()
          ]);
        }
        this.productFilter = this.getComplexProductFilter;
        if (this.productFilter?.code === 'DELIVERY_BY_SHIPPING_SUPPORTED') {
          if (!this.productFilter.global_exclude_conditions) {
            this.productFilter.global_exclude_conditions = GlobalFilterExcludeConditionsRestDtoModel.createWithDefaults();
          }
        }
      } catch (exceptions: any) {
        this.exceptions = exceptions;
      }

      this.isReady = true;
    },
    async submitData() {
      try {
        await this.saveComplexProductFilter(this.code);
        await this.afterSave();
      } catch (exceptions: any) {
        window.scrollTo(0, 0);
        this.exceptions = exceptions;
      }
    },
    labelUpdated() {
      this.labelKey++;
    }
  }
});
