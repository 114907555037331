
import {defineComponent} from "vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {CodeDescriptionRestDto, MultilingualProductLabelRestDto} from "@/api/pharma-cpc-mgmt/models";
import {PropType} from "vue/dist/vue";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";
import {ProductLabelRestService} from "@/services/rest/cpc-mgmt/ProductLabelRestService";
import {mapActions, mapGetters} from "vuex";
import ColorPicker from "@/components/UI/ColorPicker.vue";
import {MultilingualProductLabelRestDtoModel} from "@/models/api/pharma-cpc-mgmt/MultilingualProductLabelRestDtoModel";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";
import {AutoSaveFeedbackStatusEnum} from "@/models/enum/AutoSaveFeedbackStatusEnum";
import AutoSaveFeedback from "@/components/UI/AutoSaveFeedback.vue";

export default defineComponent({
  name: "ProductLabel",
  emits: ['productLabelUpdated'],
  components: {
    AutoSaveFeedback,
    ColorPicker,
    I18nInputTextEditor,
    AlertError2,
  },
  data: () => ({
    productLabel: null as MultilingualProductLabelRestDto | null,
    id: null as string | null,
    pageUI: UIStateDto.createWithDefaults(),
    mode: 'new',
    overwriteColorScheme: false,
    autoSaveFeedbackStatus: AutoSaveFeedbackStatusEnum.NONE,
  }),
  props: {
    discountSchemeId: {
      type: String as PropType<string | null>,
      required: false
    },
    productFilterCode: {
      type: String as PropType<string | null>,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    activeLanguage: {
      type: String,
      required: true,
    }
  },
  mounted() {
    if (!this.discountSchemeId && !this.productFilterCode) {
      throw new Error('Incorrect usage of component.  Either discountSchemeId or productFilterCode is required');
    }
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cpc_enum', ['getEnumByClassname']),
    colorSchemeOptions(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCpcEnum.COLOR_SCHEME_COLOR);
    },
    layoutOptions(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCpcEnum.PRODUCT_LABEL_LAYOUT);
    },
    positionOptions(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameCpcEnum.PRODUCT_LABEL_POSITION);
    },
    colorScheme(): String | undefined {
      return this.getEnumByClassname(EnumClassNameCpcEnum.COLOR_SCHEME_COLOR)
          .find((colorScheme: CodeDescriptionRestDto) => colorScheme.code === this.productLabel?.scheme_color_code)?.description;
    },
    layout(): String | undefined {
      return this.getEnumByClassname(EnumClassNameCpcEnum.PRODUCT_LABEL_LAYOUT)
          .find((layout: CodeDescriptionRestDto) => layout.code === this.productLabel?.layout_code)?.description;
    },
    position(): String | undefined {
      return this.getEnumByClassname(EnumClassNameCpcEnum.PRODUCT_LABEL_POSITION)
          .find((position: CodeDescriptionRestDto) => position.code === this.productLabel?.position_code)?.description;
    },
  },
  methods: {
    ...mapActions('cpc_enum', ["findManyEnumByClassname"]),
    async reloadContent() {
      this.pageUI
          .clearError()
          .setNotReady();

      try {
        const enumPayload = {
          classNames: [
            EnumClassNameCpcEnum.COLOR_SCHEME_COLOR,
            EnumClassNameCpcEnum.PRODUCT_LABEL_LAYOUT,
            EnumClassNameCpcEnum.PRODUCT_LABEL_POSITION,
          ],
        };
        await Promise.all([
          this.findManyEnumByClassname(enumPayload),
        ]);

        let labelResult = null;
        if (this.discountSchemeId) {
          labelResult = await ProductLabelRestService.getInstance().getProductLabelForDiscountScheme(this.discountSchemeId);
        } else {
          labelResult = await ProductLabelRestService.getInstance().getProductLabelForProductFilter(this.productFilterCode);
        }
        this.mode = 'edit';
        this.id = labelResult.id;
        this.productLabel = labelResult.label;
        this.overwriteColorScheme = !!this.productLabel?.background_color;
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.NONE;
      } catch (exceptions: any) {
        if (exceptions.response?.status === 404) {
          if (!this.readonly) {
            this.mode = 'new';
            this.id = null;
            this.productLabel = MultilingualProductLabelRestDtoModel.createWithDefaults();
            this.overwriteColorScheme = false;
            this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.NONE;
          }
        } else {
          this.setExceptions(exceptions);
          window.scrollTo(0, 0);
        }
      } finally {
        this.pageUI.setReady();
      }
    },
    async saveProductLabel() {
      this.pageUI
          .clearError()
          .setNotReady();
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.BUSY;

      try {
        if (this.mode === 'edit') {
          await ProductLabelRestService.getInstance().updateProductLabel(this.id, this.productLabel);
        } else {
          if (this.discountSchemeId) {
            await ProductLabelRestService.getInstance().createProductLabelForDiscountScheme(this.discountSchemeId, this.productLabel);
          } else if (this.productFilterCode) {
            await ProductLabelRestService.getInstance().createProductLabelForNamedProductFilter(this.productFilterCode, this.productLabel);
          }
        }
        await this.reloadContent();
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.SUCCESS;
        this.$emit('productLabelUpdated');
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async deleteProductLabel() {
      this.pageUI
          .clearError()
          .setNotReady();
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.BUSY;

      try {
        if (this.mode === 'edit') {
          await ProductLabelRestService.getInstance().deleteProductLabel(this.id);
        }
        await this.reloadContent();
        this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.SUCCESS;
        this.$emit('productLabelUpdated');
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    toggleOverwriteColorScheme() {
      this.setHasUnsavedChanges();
      this.overwriteColorScheme = !this.overwriteColorScheme;
      if (this.productLabel) {
        if (this.overwriteColorScheme) {
          this.productLabel.background_color = ColorRestDtoModel.createWithDefaults();
          this.productLabel.text_color = ColorRestDtoModel.createWithDefaults();
        } else {
          this.productLabel.background_color = null;
          this.productLabel.text_color = null;
        }
      }
    },
    setHasUnsavedChanges() {
      this.autoSaveFeedbackStatus = AutoSaveFeedbackStatusEnum.UNSAVED_CHANGES;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
          .catch(exceptions, true);
    }
  }
});
